// 新增生活通话题
const addTopicUrl = `/gateway/hc-neighbour/topic/addTopic`;
// 话题列表查询
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 获取生活通子分类
const getLifeNewsTypeUrl = `/gateway/hc-neighbour/topic/getLifeNewsType`;
// 删除话题
const deleteTopicUrl = `/gateway/hc-neighbour/topic/deleteTopic`;
// 编辑
const updateTopicUrl = `/gateway/hc-neighbour/topic/updateTopic`;
export {
  addTopicUrl,
  getTopicListUrl,
  getLifeNewsTypeUrl,
  deleteTopicUrl,
  updateTopicUrl,
};
