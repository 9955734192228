<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="lifeExpertList"
          v-model="searchParam.topicType"
          @change="lifeExpertChange"
          label="生活通分类"
        />
        <v-input
          label="标题"
          placeholder="请输入标题"
          v-model="searchParam.topicTitle"
        ></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <!-- 新增/编辑弹框 -->
    <v-dialog
      :title="dialogTitle"
      v-model="dialogFormVisible"
      width="50%"
      sureTxt="保存"
      @confirm="submit"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="生活通分类">
          <v-select
            clearable
            :options="lifeExpertList_b"
            v-model="temp.topicType"
          />
        </el-form-item>
        <el-form-item label="标题">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.topicTitle"
          ></v-input>
        </el-form-item>
        <el-form-item label="内容" prop="neighborhoodText">
          <div id="editor">
            <v-ueditor v-model="temp.topicContent" />
          </div>
        </el-form-item>
        <el-form-item label="是否关闭点赞">
          <v-radio :radioObj="isLikeObj" v-model="temp.closePraise"></v-radio>
        </el-form-item>

        <el-form-item label="是否关闭评价">
          <v-radio :radioObj="isLikeObj" v-model="temp.closeComment"></v-radio>
        </el-form-item>
        <el-form-item label="是否置顶">
          <v-radio :radioObj="isLikeObj" v-model="temp.isTop"></v-radio>
        </el-form-item>
        <el-form-item label="置顶排序">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.isTopSort"
          ></v-input>
        </el-form-item>
        <el-form-item label="发布状态">
          <v-select :options="releaseStatusOps" v-model="temp.status" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapHelper } from "@/utils/common.js";
import { Message, MessageBox } from "element-ui";
import {
  addTopicUrl,
  getTopicListUrl,
  getLifeNewsTypeUrl,
  deleteTopicUrl,
  updateTopicUrl,
} from "./api.js";
import { isTopMap, releaseStatusOps } from "./map.js";
export default {
  name: "lifeExpert",
  data() {
    return {
      releaseStatusOps: releaseStatusOps(),
      dialogFormVisible: false,
      dialogTitle: "",
      tableUrl: getTopicListUrl,
      searchParam: {
        topicType: null,
        topicTypes: localStorage.getItem("topicTypes"),
        topicTitle: null,
      },
      lifeExpertList: [
        {
          value: null,
          label: "全部",
        },
      ],
      lifeExpertList_b: [],
      topicTypes: null,
      headers: [
        {
          prop: "topicType",
          label: "生活通分类",
          formatter: (row, prop) => {
            return this.lifeExpertListMap[row[prop]] || "--";
          },
        },
        {
          prop: "topicTitle",
          label: "标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "isTop",
          label: "是否置顶",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closePraise",
          label: "是否点赞",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closeComment",
          label: "是否评论",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
      ],
      lifeExpertListMap: null,
      temp: {
        topicType: null,
        topicTitle: null,
        topicContent: null,
        closePraise: null,
        closeComment: null,
        isTop: null,
        isTopSort: null,
        status: null,
        id: null,
      },
      isLikeObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
    };
  },
  mounted() {
    this.getTopicTypes();
  },
  methods: {
    submit() {
      if (!this.temp.topicType || this.temp.topicType == "") {
        this.$message.error("请选择生活通分类！");
        return;
      }
      if (!this.temp.topicTitle || this.temp.topicTitle == "") {
        this.$message.error("请输入标题！");
        return;
      }
      if (!this.temp.topicContent || this.temp.topicContent == "") {
        this.$message.error("请输入内容！");
        return;
      }
      if (this.temp.closePraise == null) {
        this.$message.error("请选择是否点赞！");
        return;
      }
      if (this.temp.closeComment == null) {
        this.$message.error("请选择是否评论！");
        return;
      }
      if (this.temp.isTop == null) {
        this.$message.error("请选择是否置顶！");
        return;
      }
      if (!this.temp.isTopSort || this.temp.isTopSort == "") {
        this.$message.error("请输入置顶排序！");
        return;
      }
      if (this.temp.status == null) {
        this.$message.error("请选择发布状态！");
        return;
      }
      if (this.dialogTitle == "新增") {
        let params = {
          topicType: this.temp.topicType,
          topicTitle: this.temp.topicTitle,
          topicContent: this.temp.topicContent,
          closePraise: this.temp.closePraise,
          closeComment: this.temp.closeComment,
          isTop: this.temp.isTop,
          isTopSort: this.temp.isTopSort,
          status: this.temp.status,
          createUser: this.$store.state.app.userInfo.userId,
          tenantId: this.$store.state.app.userInfo.tenantId,
        };
        this.$axios.post(`${addTopicUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            Message.success(res.msg);
            this.$refs.list.search();
          }
        });
      } else if (this.dialogTitle == "编辑") {
        let params = {
          id: this.temp.id,
          topicType: this.temp.topicType,
          topicTitle: this.temp.topicTitle,
          topicContent: this.temp.topicContent,
          closePraise: this.temp.closePraise,
          closeComment: this.temp.closeComment,
          isTop: this.temp.isTop,
          isTopSort: this.temp.isTopSort,
          status: this.temp.status,
          createUser: this.$store.state.app.userInfo.userId,
          tenantId: this.$store.state.app.userInfo.tenantId,
        };
        this.$axios.post(`${updateTopicUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            Message.success(res.msg);
            this.$refs.list.search();
          }
        });
      }
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toEdit(data) {
      console.log(data.topicType);
      console.log(this.lifeExpertList_b);
      this.dialogFormVisible = true;
      this.dialogTitle = "编辑";
      this.temp.topicType = String(data.topicType);
      this.temp.id = data.id;
      this.temp.topicTitle = data.topicTitle;
      this.temp.closePraise = data.closePraise;
      this.temp.closeComment = data.closeComment;
      this.temp.isTop = data.isTop;
      this.temp.isTopSort = data.isTopSort;
      this.temp.status = data.status;
      this.$nextTick(() => {
        this.temp.topicContent = data.topicContent;
      });
    },
    toAdd() {
      this.dialogFormVisible = true;
      this.dialogTitle = "新增";
      this.temp.topicType = null;
      this.temp.topicTitle = null;
      this.temp.topicContent = null;
      this.temp.closePraise = null;
      this.temp.closeComment = null;
      this.temp.isTop = null;
      this.temp.isTopSort = null;
      this.temp.status = null;
    },
    lifeExpertChange(value) {
      if (value.indexOf(",") != -1) {
        this.searchParam.topicTypes = value;
        this.searchParam.topicType = null;
        this.$refs.list.search();
      } else {
        this.searchParam.topicTypes = null;
        this.searchParam.topicType = value;
        this.$refs.list.search();
      }
    },
    getTopicTypes() {
      this.$axios.get(`${getLifeNewsTypeUrl}`).then((res) => {
        if (res.code == 200) {
          let ids = [];
          res.data.forEach((item) => {
            ids.push(item.code);
            item.value = item.code;
            item.label = item.message;
            this.lifeExpertList.push(item);
          });
          this.lifeExpertList_b = res.data;
          this.topicTypes = ids.join(",");
          this.lifeExpertList[0].value = ids.join(",");
          localStorage.setItem("topicTypes", ids.join(","));
          const { map: lifeExpertListMap, setOps: lifeExpertListOps } =
            mapHelper.setMap(this.lifeExpertList);
          this.lifeExpertListMap = lifeExpertListMap;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
