import { mapHelper } from "@/utils/common.js";

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

// 发布状态
const releaseStatus = [
  {
    value: 0,
    label: "待发布",
  },
  {
    value: 1,
    label: "已发布",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);
const { map: releaseStatusMap, setOps: releaseStatusOps } =
  mapHelper.setMap(releaseStatus);

export {
  isTopMap,
  setIsTopOps,
  releaseStatus,
  releaseStatusMap,
  releaseStatusOps,
};
